import React from 'react';
import './App.css';
import is_palindrome from './palindrome'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          <b>{is_palindrome(new Date()) ? 'Yes' : 'No' }</b>
        </p>
      </header>
    </div>
  );
}

export default App;
