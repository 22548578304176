export default function is_palindrome(date: Date) {
  const two_digit_year = date.getFullYear().toString().substr(-2)
  const month = date.getMonth() + 1
  const day = date.getDate()
  const date_str = `${month}${day}${two_digit_year}`

  console.log(date_str)

  return date_str === date_str.split("").reverse().join("")
}
